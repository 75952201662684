import TomSelect from "tom-select";

if (document.readyState !== 'loading') {
    handleSearch();
} else {
    document.addEventListener('DOMContentLoaded', function () {
        handleSearch();
    });
}

function handleSearch() {
    const searchEndpoint = '/search';
    const autocompleteEndpoint = '/query_suggestion';
    const federationsEndpoint = '/content/tennis/lv.federations.json';

    const getSearchSuggestion = async (searchTerm, searchEngineUrl, autocompleteEndpoint) => {
        const url = searchEngineUrl + autocompleteEndpoint + '?query=' + searchTerm;
        const options = {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                "Content-Type": "application/json",
            }
        }
        let results = [];
        await fetch(url, options)
            .then(response => response.json())
            .then(data => {
                if (!data.error && data.results && data.results.documents) {
                    results = data.results.documents;
                } else {
                    throw new Error(data.error);
                }
            })
            .catch((error) => {
                throw new Error(error);
            });

        results = results.filter((result) => {
            return result.suggestion.split(' ').length <= 2;
        });
        return results;
    }

    const getSearchedResults = async (searchTerm, activeTags, activeFederations, page, searchEngineUrl, searchEndpoint) => {
        const url = searchEngineUrl + searchEndpoint + '?query=' + searchTerm;
        const body = {
            "query": searchTerm,
            "page": {
                "size": 10,
                "current": page
            }
        };

        if (activeFederations && activeFederations.length > 0) {
            body.filters = {
                "all": [
                    { "federation": activeFederations },
                ]
            };
        }

        const options = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body)
        };

        let output = {};
        await fetch(url, options)
            .then(response => response.json())
            .then(data => {
                if (data.error) {
                    throw new Error(data.error);
                } else if (data.results) {
                    output = data;
                }
            })
            .catch((error) => {
                throw new Error(error);
            });

        return output;
    }

    const getFederations = async (federationsEndpoint) => {
        const url = federationsEndpoint;
        const options = {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                "Content-Type": "application/json",
            }
        }
        let results = [];
        await fetch(url, options)
            .then(response => response.json())
            .then(data => {
                if (data.federations) {
                    results = data.federations;
                } else {
                    throw new Error(data.error);
                }
            })
            .catch((error) => {
                throw new Error(error);
            });

        return results;
    }

    const debounce = (callback, wait) => {
        let timeoutId = null;
        return (...args) => {
            window.clearTimeout(timeoutId);
            timeoutId = window.setTimeout(() => {
                callback.apply(null, args);
            }, wait);
        };
    }

    const headers = document.querySelectorAll('.cmp-header');

    headers.forEach(function (header) {
        const searchWrapper = header.querySelector('.cmp-header__search');
        const searchForm = searchWrapper.getElementsByTagName('form')[0];
        const searchInput = header.querySelector('.cmp-header__search-input');
        const searchAutocomplete = header.querySelector('.cmp-header__search-autocomplete');
        const searchSuggestions = header.querySelector('.cmp-header__search-suggestion');
        const searchSuggestionsWrapper = header.querySelector('.cmp-header__search-suggestion-wrapper');
        const searchSuggestionsLabel = header.querySelector('.cmp-header__search-suggestion-label');
        const searchSuggestionsList = header.querySelector('.cmp-header__search-suggestion-list');

        const searchEngineUrl = searchForm.getAttribute('data-search');

        const renderSearchSuggestions = (suggestions, searchTerm) => {
            if (suggestions.length > 0) {
                // const autocompleteMerged = searchTerm + suggestions[0].suggestion.slice(searchTerm.length);
                const suggestionItems = suggestions.map(
                    suggestion => `<li class="cmp-header__search-suggestion-item" role="option"><button type="button" class="cmp-header__search-suggestion-link">${suggestion.suggestion}</button></li>`
                )

                searchSuggestions.classList.add('cmp-header__search-suggestion--active');
                searchSuggestionsWrapper.classList.add('cmp-header__search-suggestion-wrapper--active');
                searchSuggestionsLabel.classList.add('cmp-header__search-suggestion-label--active');
                searchInput.classList.add('cmp-header__search-input--active');
                // searchAutocomplete.value = autocompleteMerged;
                searchSuggestionsList.innerHTML = suggestionItems.join('');

            } else {
                resetSearchSuggestions();
            }
        }

        const resetSearchSuggestions = () => {
            searchSuggestions.classList.remove('cmp-header__search-suggestion--active');
            searchSuggestionsWrapper.classList.remove('cmp-header__search-suggestion-wrapper--active');
            searchSuggestionsLabel.classList.remove('cmp-header__search-suggestion-label--active');
            searchInput.classList.remove('cmp-header__search-input--active');
            searchAutocomplete.value = '';
            searchSuggestionsList.innerHTML = '';
        }

        // search suggestions
        const handleAutocomplete = debounce(() => {
            if (searchInput.value.length > 0) {
                getSearchSuggestion(searchInput.value, searchEngineUrl, autocompleteEndpoint)
                    .then(suggestions => renderSearchSuggestions(suggestions, searchInput.value))
                    .catch(error => console.log(error));
            }
        }, 300);

        searchInput.addEventListener('keyup', (event) => {
            if (event.keyCode !== 27 && event.keyCode !== 37 && event.keyCode !== 38 && event.keyCode !== 39 && event.keyCode !== 40 && event.keyCode !== 13) {
                resetSearchSuggestions();
                handleAutocomplete();
            }
        });

        searchInput.addEventListener('keydown', (event) => {
            // esc - close suggestions
            if (event.keyCode === 27) {
                searchInput.value = '';
                resetSearchSuggestions();
            }

            // navigate through suggestions
            if (event.keyCode === 38 || event.keyCode === 40) {
                event.preventDefault();
                const activeSuggestion = searchSuggestionsList.querySelector('.cmp-header__search-suggestion-item--active');
                if (activeSuggestion) {
                    let nextSuggestion;
                    if (event.keyCode === 40) {
                        nextSuggestion = activeSuggestion.nextElementSibling === null ? searchSuggestionsList.querySelector('.cmp-header__search-suggestion-item') : activeSuggestion.nextElementSibling;
                    } else {
                        nextSuggestion = activeSuggestion.previousElementSibling === null ? searchSuggestionsList.querySelector('.cmp-header__search-suggestion-item:last-child') : activeSuggestion.previousElementSibling;
                    }
                    activeSuggestion.classList.remove('cmp-header__search-suggestion-item--active');
                    if (nextSuggestion) {
                        nextSuggestion.classList.add('cmp-header__search-suggestion-item--active');
                    } else {
                        searchInput.focus();
                    }
                } else {
                    const firstSuggestion = searchSuggestionsList.querySelector('.cmp-header__search-suggestion-item');
                    firstSuggestion.classList.add('cmp-header__search-suggestion-item--active');
                }
            }

            // enter - fill first suggestion or selected suggestion
            if (event.keyCode === 13) {
                const activeSuggestion = searchSuggestionsList.querySelector('.cmp-header__search-suggestion-item--active');
                if (activeSuggestion) {
                    event.preventDefault();
                    searchInput.value = activeSuggestion.innerText;
                    searchForm.requestSubmit();
                    // resetSearchSuggestions();
                } else if (searchAutocomplete.value.length > 0 && searchInput.value !== searchAutocomplete.value) {
                    event.preventDefault();
                    searchInput.value = searchAutocomplete.value;
                    searchForm.requestSubmit();
                    // resetSearchSuggestions();
                } else {
                    event.preventDefault();
                    searchForm.requestSubmit();
                    searchInput.blur();
                }
            }
        });

        // Fill search input with selected suggestion
        searchSuggestionsList.addEventListener('click', (event) => {
            if (event.target.classList.contains('cmp-header__search-suggestion-link')) {
                searchInput.value = event.target.innerText;
                searchForm.requestSubmit();
                resetSearchSuggestions();
            }
        });
    });

    const searchResults = document.querySelectorAll('.cmp-search-results');

    searchResults.forEach(function (searchResult) {
        const searchForm = searchResult.getElementsByTagName('form')[0];
        const searchInput = searchResult.querySelector('.cmp-search-results__search-input');
        const searchAutocomplete = searchResult.querySelector('.cmp-search-results__search-autocomplete');
        const searchSuggestions = searchResult.querySelector('.cmp-search-results__search-suggestion');
        const searchSuggestionsList = searchResult.querySelector('.cmp-search-results__search-suggestion-list');
        const searchFieldWrap = searchResult.querySelector('.cmp-search-results__search-field-wrap');
        const searchReset = searchResult.querySelector('.cmp-search-results__reset-button');
        const searchResultsList = searchResult.querySelector('.cmp-search-results__list');
        const searchResultsLoading = searchResult.querySelector('.cmp-search-results__loading');
        const searchResultsEmptyState = searchResult.querySelector('.cmp-search-results__empty-state');
        const searchResultsErrorStateText = searchResultsEmptyState.querySelector('.v-alert');
        const searchResultsErrorState = searchResult.querySelector('.cmp-search-results__error-state');
        const searchTags = searchResult.querySelectorAll('.v-filter-tags__item');
        const searchHead = searchResult.querySelector('.cmp-search-results__search-head');
        const searchFooter = searchResult.querySelector('.cmp-search-results__footer');
        const searchFooterLink = searchResult.querySelector('.cmp-search-results__footer-link');
        const tenantMeta = document.querySelector('meta[property="tenant"]');

        searchResultsErrorStateText.innerHTML = 'Keine Ergebnisse für Ihre Suche. Bitte passen Sie ihre Anfrage und ggf. die Filter an.';

        const searchEngineUrl = searchForm.getAttribute('data-search');

        let currentTenant = tenantMeta ? tenantMeta.getAttribute('content') : 'tennisde';
        let activeTags = [];
        let activeFederations = [];
        let currentPage = 1;
        let currentTotalPages = 1;

        if (currentTenant === 'tennisde' || currentTenant === 'tennis') {
            currentTenant = '';
        }

        const areas = [
            {
                "value": "",
                "text": "tennis.de"
            },
            {
                "value": "dtb",
                "text": "DTB"
            },
            {
                "value": "landesverbande",
                "text": "Landesverbände"
            },
        ]

        const formatFederations = (data) => {
            const federations = data.map(federation => {
                return {
                    value: federation.name,
                    text: federation.title,
                }
            });
            federations.unshift({
                "value": "all",
                "text": "Alle",
            });
            return federations;
        }

        const searchAutoComplete = (suggestions, searchTerm) => {
            if (suggestions.length > 0) {
                // const autocompleteMerged = searchTerm + suggestions[0].suggestion.slice(searchTerm.length);
                const suggestionItems = suggestions.map(
                    suggestion => `<li class="cmp-search-results__search-suggestion-item" role="option"><button type="button" class="cmp-search-results__search-suggestion-link">${suggestion.suggestion}</button></li>`
                )

                searchSuggestions.classList.add('cmp-search-results__search-suggestion--active');
                // searchAutocomplete.value = autocompleteMerged;
                searchFieldWrap.classList.add('cmp-search-results__search-field-wrap--active');
                searchReset.tabIndex = 0;
                searchInput.classList.add('cmp-search-results__search-input--active')
                searchSuggestionsList.innerHTML = suggestionItems.join('');
            } else {
                resetSearchAutocomplete();
            }
        }

        const resetSearchAutocomplete = () => {
            searchSuggestions.classList.remove('cmp-search-results__search-suggestion--active');
            searchInput.classList.remove('cmp-search-results__search-input--active');
            searchAutocomplete.value = '';
            searchSuggestionsList.innerHTML = '';
        }

        const renderSearchResults = (data, searchTerm, newPage) => {
            if (data.results && data.results.length > 0) {
                let websiteUrl = data.results[0].url.raw.split('/');
                websiteUrl = websiteUrl[0] + '//' + websiteUrl[2] + '/';
                const output = data.results.map(
                    result => `<div class="cmp-search-results__item">
                        <div class="cmp-search-results__item-go-icon-wrapper">
                            <a href="${result.url.raw}" class="cmp-search-results__item-go-icon">
                                <span class="cmp-search-results__item-go-icon-image">Weiter</span>
                            </a>
                        </div>
                        <div class="cmp-search-results__item-content-wrapper">
                            <ul class="cmp-search-results__item-breadcrumb">
                                ${result.url_path_dir1 && result.url_path_dir1 !== "undefined" && result.url_path_dir1 !== "tennis" ? `
                                    <li class="cmp-search-results__item-breadcrumb-item">
                                        <a href="${websiteUrl + result.url_path_dir1.raw + '/'}">${result.url_path_dir1.raw.replaceAll('-', ' ')}</a>
                                    </li>
                                ` : ''}
                                ${result.url_path_dir2 && result.url_path_dir2 !== "undefined" ? `
                                    <li class="cmp-search-results__item-breadcrumb-item">
                                        <a href="${websiteUrl + result.url_path_dir1.raw + '/' + result.url_path_dir2.raw + '/'}">${result.url_path_dir2.raw.replaceAll('-', ' ')}</a>
                                    </li>
                                ` : ''}
                                ${result.url_path_dir3 && result.url_path_dir3 !== "undefined" ? `
                                    <li class="cmp-search-results__item-breadcrumb-item">
                                        <a href="${websiteUrl + result.url_path_dir1.raw + '/' + result.url_path_dir2.raw + '/' + result.url_path_dir3.raw + '/'}">${result.url_path_dir3.raw.replaceAll('-', ' ')}</a>
                                    </li>
                                ` : ''}
                                ${result.url_path_dir4 && result.url_path_dir4 !== "undefined" && result.url_path_dir1 === "tennis" ? `
                                    <li class="cmp-search-results__item-breadcrumb-item">
                                        <a href="${websiteUrl + result.url_path_dir4.raw + '/'}">${result.url_path_dir4.raw.replaceAll('-', ' ')}</a>
                                    </li>
                                ` : ''}
                            </ul>
                            <div class="cmp-search-results__item-content${result.image_url && result.image_url !== "" ? ` cmp-search-results__item-content--has-image` : ''}">
                                <div class="cmp-search-results__item-content-main">
                                    ${result?.tags?.raw.includes('video') || result?.tags?.raw.includes('image') ? `
                                        <strong class="cmp-search-results__item-toptag">
                                            <a href="${result.url.raw}">
                                            ${result?.tags?.raw.includes('video') ? `Video` : ''}
                                            ${result?.tags?.raw.includes('image') && result?.tags?.raw.includes('video') ? `, ` : ''}
                                            ${result?.tags?.raw.includes('image') ? `Bild` : ''}
                                            </a>
                                        </strong>
                                    ` : ''}
                                    <h3 class="cmp-search-results__item-title">
                                        <a href="${result.url.raw}">${result.title.raw}</a>
                                    </h3>
                                    <div class="cmp-search-results__item-text">
                                        <a href="${result.url.raw}">
                                            ${result.meta_description !== undefined ? highlightSearchTerm(result.meta_description.raw, searchTerm) : ""}
                                        </a>
                                    </div>
                                </div>
                                ${result.image_url && result.image_url.raw ? `
                                    <div class="cmp-search-results__image-wrapper${result?.tags?.raw.includes('video') ? ` cmp-search-results__image-wrapper--is-video` : ''}">
                                        <a href="${result.url.raw}">
                                            <img src="${result.image_url.raw}" alt="${result.title.raw}">
                                        </a>
                                    </div>
                                ` : ''}
                            </div>
                        </div>
                        <div class="cmp-search-results__item-category-wrapper">
                       ${result?.tags?.raw.includes('news') ? `
                            <a href="${result.url.raw}" class="cmp-search-results__item-category">
                                <div class="cmp-search-results__item-category-icon cmp-search-results__item-category-icon--news"></div>
                                <div class="cmp-search-results__item-category-label">News</div>
                            </a>
                        ` :
                        result?.tags?.raw.includes('video') ? `
                            <a href="${result.url.raw}" class="cmp-search-results__item-category">
                                <div class="cmp-search-results__item-category-icon cmp-search-results__item-category-icon--video"></div>
                                <div class="cmp-search-results__item-category-label">Videos</div>
                            </a>
                        ` :
                        result?.tags?.raw.includes('image') ? `
                            <a href="${result.url.raw}" class="cmp-search-results__item-category">
                                <div class="cmp-search-results__item-category-icon cmp-search-results__item-category-icon--bilder"></div>
                                <div class="cmp-search-results__item-category-label">Bilder</div>
                            </a>
                        ` : ''}
                        </div>
                    </div>`
                );

                if (newPage) {
                    searchResultsList.innerHTML += output.join('');
                } else {
                    searchResultsList.innerHTML = output.join('');
                }

                searchResultsList.classList.add('cmp-search-results__list--active');

                searchHead.innerHTML = `<li class="cmp-search-results__search-head-item">
                    <span>Suchergebnisse</span>
                    <strong>${data.meta.page.total_results}</strong>
                </li>`;

                setPagination(data.meta.page.current, data.meta.page.total_pages);
            } else {
                resetSearchResults();
                setNoResults(true);
            }
        }

        const resetSearchResults = () => {
            searchResultsList.innerHTML = '';
            searchResultsList.classList.remove('cmp-search-results__list--active');
            searchHead.innerHTML = '';
            setPagination(1, 1);
        };

        const highlightSearchTerm = (text, searchTerm) => {
            const regex = new RegExp(searchTerm, 'gi');
            return text.replace(regex, '<strong>$&</strong>');
        }

        const setLoading = (isLoading) => {
            if (isLoading) {
                searchResultsLoading.classList.add('cmp-search-results__loading--active');
                searchFooterLink.disabled = true;
            } else {
                searchResultsLoading.classList.remove('cmp-search-results__loading--active');
                searchFooterLink.disabled = false;
            }
        }

        const setNoResults = (hasNoResults) => {
            if (hasNoResults) {
                searchResultsEmptyState.classList.add('cmp-search-results__empty-state--active');
            } else {
                searchResultsEmptyState.classList.remove('cmp-search-results__empty-state--active');
            }
        }

        const setErrorMessage = (hasError) => {
            if (hasError) {
                searchResultsErrorState.classList.add('cmp-search-results__error-state--active');
            } else {
                searchResultsErrorState.classList.remove('cmp-search-results__error-state--active');
            }
        }

        const setPagination = (current, total) => {
            const isVisible = current && total && current < total;
            currentPage = current;
            currentTotalPages = total;

            if (isVisible) {
                searchFooter.classList.add('cmp-search-results__footer--active');
            } else {
                searchFooter.classList.remove('cmp-search-results__footer--active');
            }
        }

        // search suggestions
        const handleAutocomplete = debounce(() => {
            if (searchInput.value.length > 0) {
                getSearchSuggestion(searchInput.value, searchEngineUrl, autocompleteEndpoint)
                    .then(suggestions => searchAutoComplete(suggestions, searchInput.value))
                    .catch(error => console.error(error));
            } else {
                searchFieldWrap.classList.remove('cmp-search-results__search-field-wrap--active');
                searchReset.tabIndex = -1;
            }
        });

        const updateQueryString = (uri, params) => {
            const url = new URL(uri);
            Object.keys(params).forEach(key => {
                if (params[key] === undefined || params[key] === null) {
                    url.searchParams.delete(key);
                } else {
                    url.searchParams.set(key, params[key]);
                }
            });
            return url.href;
        };

        const loadSearchData = (newPage) => {
            if (searchInput.value.length > 0) {
                setLoading(true);
                setNoResults(false);
                setErrorMessage(false);

                const queryParams = {
                    query: searchInput.value,
                    federations: activeFederations.join(',')
                };
                const updatedUrl = updateQueryString(window.location.href, queryParams);
                window.history.pushState({}, '', updatedUrl);

                getSearchedResults(searchInput.value, activeTags, activeFederations, currentPage, searchEngineUrl, searchEndpoint)
                    .then(data => renderSearchResults(data, searchInput.value, newPage))
                    .catch(error => {
                        console.error(error);
                        setErrorMessage(true);
                    })
                    .finally(() => setLoading(false));
            }
        };

        const initSearch = (federations, areas) => {
            const urlParams = new URLSearchParams(window.location.search);
            const initialFederations = urlParams.get('federations');
            const initialSearchQuery = urlParams.get('query');

            const { areaSelect, federationSelect, currentTenant, activeFederationsWrapper } = renderSelects(federations, areas);
            selectDefaultState(areaSelect, federationSelect, currentTenant, activeFederationsWrapper, initialFederations);

            if (initialSearchQuery && initialSearchQuery.length > 0) {
                searchInput.value = initialSearchQuery;
                searchFieldWrap.classList.add('cmp-search-results__search-field-wrap--active');
                searchReset.tabIndex = 0;
                searchInput.classList.add('cmp-search-results__search-input--active')
                loadSearchData();
            }
        }

        const renderSelects = (federations, areas) => {
            const selectWrapper = document.createElement('div');
            selectWrapper.classList.add('cmp-search-results__select-wrapper');
            searchFieldWrap.after(selectWrapper);
            const selectTitle = document.createElement('h2');
            selectTitle.classList.add('cmp-search-results__select-title');
            selectTitle.innerHTML = 'Suche verfeinern';
            selectWrapper.appendChild(selectTitle);

            const areaSelectEl = document.createElement('select');
            areaSelectEl.classList.add('cmp-search-results__select', 'cmp-search-results__select--area');
            areaSelectEl.setAttribute('name', 'area');
            areaSelectEl.setAttribute('id', 'area');
            areaSelectEl.setAttribute('multiple', 'multiple');
            selectWrapper.appendChild(areaSelectEl);

            const federationSelectEl = document.createElement('select');
            federationSelectEl.classList.add('cmp-search-results__select', 'cmp-search-results__select--federation', 'ts-wrapper--hidden');
            federationSelectEl.setAttribute('name', 'federation');
            federationSelectEl.setAttribute('id', 'federation');
            federationSelectEl.setAttribute('multiple', 'multiple');
            selectWrapper.appendChild(federationSelectEl);

            const activeFederationsWrapper = document.createElement('div');
            activeFederationsWrapper.classList.add('cmp-search-results__active-federations');
            selectWrapper.after(activeFederationsWrapper);
            const activeFederationsList = document.createElement('ul');
            activeFederationsList.classList.add('cmp-search-results__active-federations-list');
            activeFederationsWrapper.appendChild(activeFederationsList);

            const activeFederationsReset = document.createElement('button');
            activeFederationsReset.classList.add('cmp-search-results__active-federations-reset');
            activeFederationsReset.innerHTML = 'Filter zurücksetzen';
            activeFederationsWrapper.appendChild(activeFederationsReset);

            const areaSelect = new TomSelect(areaSelectEl, {
                allowEmptyOption: true,
                options: areas,
                controlInput: null,
                hideSelected: false,
                itemClass: 'ts-item-hidden',
                plugins: {
                    'checkbox_options': {
                        'checkedClassNames':   ['ts-checked'],
                        'uncheckedClassNames': ['ts-unchecked'],
                    },
                },
                onInitialize: function (){
                    updateSelectLabel(this, 'area', true);
                },
                onChange: function() {
                    updateSelectLabel(this, 'area');
                    setActiveFederations(this, federationSelect, activeFederationsWrapper);
                    this.wrapper.classList.add('ts-wrapper--touched');
                },
                onFocus: function() {
                    changeSaveActiveState(this, true);
                    this.wrapper.classList.remove('ts-wrapper--touched');
                },
                onBlur: function() {
                    changeSaveActiveState(this, false);
                }
            });

            let federationNotResetFlag = false;
            const federationSelect = new TomSelect(federationSelectEl, {
                allowEmptyOption: true,
                options: federations,
                items: ['all'],
                controlInput: null,
                hideSelected: false,
                itemClass: 'ts-item-hidden',
                plugins: {
                    'checkbox_options': {
                        'checkedClassNames':   ['ts-checked'],
                        'uncheckedClassNames': ['ts-unchecked'],
                    }
                },
                onInitialize: function (){
                    updateSelectLabel(this, 'federation', true);
                },
                onItemAdd(value) {
                    if (!federationNotResetFlag) {
                        if (value === 'all') {
                            federationNotResetFlag = true;
                            this.clear(true);
                            this.addItem('all', true);
                            federationNotResetFlag = false;
                        } else if (this.items.includes('all')) {
                            this.removeItem('all', true);
                        }
                    }
                },
                onItemRemove() {
                    if (!federationNotResetFlag) {
                        if (this.items.length === 0) {
                            federationNotResetFlag = true;
                            this.addItem('all', true);
                            federationNotResetFlag = false;
                        }
                    }
                },
                onChange: function() {
                    updateSelectLabel(this, 'federation');
                    setActiveFederations(areaSelect, this, activeFederationsWrapper);
                    this.wrapper.classList.add('ts-wrapper--touched');
                },
                onFocus: function() {
                    changeSaveActiveState(this, true);
                    this.wrapper.classList.remove('ts-wrapper--touched');
                },
                onBlur: function() {
                    changeSaveActiveState(this, false);
                },
            });

            activeFederationsReset.addEventListener('click', () => {
                areaSelect.clear(true);
                federationSelect.clear(true);
                federationSelect.addItem('all', true);
                activeFederations = [currentTenant];
                if (areaSelect.options[currentTenant]) {
                    areaSelect.addItem(currentTenant, true);
                    federationSelect.wrapper.classList.add('ts-wrapper--hidden');
                } else if (federationSelect.options[currentTenant]) {
                    areaSelect.addItem('landesverbande', true);
                    federationSelect.wrapper.classList.remove('ts-wrapper--hidden');
                    federationSelect.addItem(currentTenant, true);
                }
                updateSelectLabel(areaSelect, 'area');
                updateSelectLabel(federationSelect, 'federation');
                renderActiveFederations(activeFederations, activeFederationsWrapper, areaSelect, federationSelect);
                federationSelect.wrapper.classList.remove('ts-wrapper--touched');
                areaSelect.wrapper.classList.remove('ts-wrapper--touched');
                loadSearchData();
            });

            return { areaSelect, federationSelect, currentTenant, activeFederationsWrapper };
        }

        function selectDefaultState(areaSelect, federationSelect, currentTenant, activeFederationsWrapper, federations) {
            if (federations) {
                const selectedFederations = federations.split(',');
                selectedFederations.forEach(federation => {
                    if (areaSelect.options[federation]) {
                        areaSelect.addItem(federation, true);
                    } else if (federationSelect.options[federation]) {
                        areaSelect.addItem('landesverbande', true);
                        federationSelect.wrapper.classList.remove('ts-wrapper--hidden');
                        federationSelect.addItem(federation, true);
                    }
                });
            } else {
                activeFederations = [currentTenant];
                if (areaSelect.options[currentTenant]) {
                    areaSelect.addItem(currentTenant, true);
                } else if (federationSelect.options[currentTenant]) {
                    areaSelect.addItem('landesverbande', true);
                    federationSelect.wrapper.classList.remove('ts-wrapper--hidden');
                    federationSelect.addItem(currentTenant, true);
                }
            }
            updateSelectLabel(areaSelect, 'area');
            updateSelectLabel(federationSelect, 'federation');
            setActiveFederations(areaSelect, federationSelect, activeFederationsWrapper);
        }

        function updateSelectLabel(instance, type, init) {
            const numberOfSelected = instance.items.length;
            const wrapper = instance.control.parentElement;
            let labelText;

            if (type === 'area') {
                labelText = numberOfSelected === 0 ? 'Bereich' : `Bereich (${numberOfSelected})`;
            } else {
                labelText = numberOfSelected === 1 && instance.items[0] === 'all' ? 'Landesverbände (Alle)' : `Landesverbände (${numberOfSelected})`;
            }

            if (init) {
                const labelDiv = document.createElement('div');
                labelDiv.classList.add('ts-custom-label');
                labelDiv.innerHTML = labelText;
                wrapper.prepend(labelDiv);
                const saveDiv = document.createElement('div');
                saveDiv.classList.add('ts-custom-save-label');
                saveDiv.innerHTML = 'Speichern';
                wrapper.prepend(saveDiv);
            } else {
                wrapper.querySelector('.ts-custom-label').innerHTML = labelText;
            }
        }

        function changeSaveActiveState(instance, state) {
            const wrapper = instance.control.parentElement;
            const saveDiv = wrapper.querySelector('.ts-custom-save-label');
            if (state) {
                saveDiv.classList.add('ts-custom-save-label--active');
                wrapper.classList.add('ts-wrapper--save-label-active');
            } else {
                saveDiv.classList.remove('ts-custom-save-label--active');
                wrapper.classList.remove('ts-wrapper--save-label-active');
                loadSearchData();
            }
        }

        function setActiveFederations(areaSelect, federationSelect, activeFederationsWrapper) {
            const areas = areaSelect.items;
            const federations = federationSelect.items;
            const federationsOptions = federationSelect.options;

            activeFederations = areas.filter(area => area !== 'landesverbande');

            if (areas.includes('landesverbande')) {
                federationSelect.wrapper.classList.remove('ts-wrapper--hidden');
                if (federations.includes('all')) {
                    const allFederations = Object.values(federationsOptions).map(opt => opt.value).filter(val => val !== 'all');
                    activeFederations = activeFederations.concat(allFederations);
                } else {
                    if (federations.length > 0) {
                        activeFederations = activeFederations.concat(federations);
                    }
                }
            } else {
                federationSelect.wrapper.classList.add('ts-wrapper--hidden');
            }

            renderActiveFederations(activeFederations, activeFederationsWrapper, areaSelect, federationSelect);
        }

        function renderActiveFederations(activeFederations, activeFederationsWrapper, areaSelect, federationSelect) {
            const activeFederationsList = activeFederationsWrapper.querySelector('.cmp-search-results__active-federations-list');
            activeFederationsList.innerHTML = '';
            const isLandesverbandeActive = areaSelect.items.includes('landesverbande');
            const isAllFederationsActive = federationSelect.items.includes('all');
            const activeItems = areaSelect.items.map(item => areaSelect.options[item]);

            if (isLandesverbandeActive && !isAllFederationsActive) {
                activeItems.push(...federationSelect.items.map(item => federationSelect.options[item]));
            }

            if (activeItems.length === 0) {
                activeFederationsWrapper.classList.remove('cmp-search-results__active-federations--active');
                areaSelect.wrapper.classList.remove('ts-wrapper--touched');
                federationSelect.wrapper.classList.remove('ts-wrapper--touched');
            } else {
                activeItems.forEach(federation => {
                    const button = document.createElement('button');
                    button.classList.add('v-filter-tags__item', 'v-filter-tags__item--medium', 'v-filter-tags__item--search', 'v-filter-tags__item--active');
                    button.innerHTML = `${federation.text} <span class="v-filter-tags__item-icon">
                                <span class="v-filter-tags__item-icon-inner"></span>
                            </span>`;
                    activeFederationsList.appendChild(button);

                    button.addEventListener('click', () => {
                        if (federation.value === 'landesverbande') {
                            areaSelect.removeItem(federation.value, true);
                            updateSelectLabel(areaSelect, 'area');
                            federationSelect.clear(true);
                            federationSelect.addItem('all', true);
                            updateSelectLabel(federationSelect, 'federation');
                            federationSelect.wrapper.classList.add('ts-wrapper--hidden');
                            federationSelect.wrapper.classList.remove('ts-wrapper--touched');
                        } else {
                            if (federationSelect.items.includes(federation.value)) {
                                federationSelect.removeItem(federation.value, true);
                                updateSelectLabel(federationSelect, 'federation');
                            } else {
                                areaSelect.removeItem(federation.value, true);
                                updateSelectLabel(areaSelect, 'area');
                            }
                        }
                        setActiveFederations(areaSelect, federationSelect, activeFederationsWrapper);
                        renderActiveFederations(activeFederations, activeFederationsWrapper, areaSelect, federationSelect);
                        loadSearchData();
                    });
                });
                activeFederationsWrapper.classList.add('cmp-search-results__active-federations--active');
            }
        }

        searchInput.addEventListener('keyup', (event) => {
            if (event.keyCode !== 27 && event.keyCode !== 37 && event.keyCode !== 38 && event.keyCode !== 39 && event.keyCode !== 40 && event.keyCode !== 13) {
                resetSearchAutocomplete();
                handleAutocomplete();
            }
        });

        searchInput.addEventListener('focus', () => {
            resetSearchAutocomplete();
            handleAutocomplete();
        });

        searchInput.addEventListener('keydown', (event) => {
            // esc - close suggestions
            if (event.keyCode === 27) {
                searchInput.value = '';
                resetSearchAutocomplete();
                searchFieldWrap.classList.remove('cmp-search-results__search-field-wrap--active');
                searchReset.tabIndex = -1;
            }

            // navigate through suggestions
            if (event.keyCode === 38 || event.keyCode === 40) {
                event.preventDefault();
                const activeSuggestion = searchSuggestionsList.querySelector('.cmp-search-results__search-suggestion-item--active');
                if (activeSuggestion) {
                    let nextSuggestion;
                    if (event.keyCode === 40) {
                        nextSuggestion = activeSuggestion.nextElementSibling === null ? searchSuggestionsList.querySelector('.cmp-search-results__search-suggestion-item') : activeSuggestion.nextElementSibling;
                    } else {
                        nextSuggestion = activeSuggestion.previousElementSibling === null ? searchSuggestionsList.querySelector('.cmp-search-results__search-suggestion-item:last-child') : activeSuggestion.previousElementSibling;
                    }
                    activeSuggestion.classList.remove('cmp-search-results__search-suggestion-item--active');
                    if (nextSuggestion) {
                        nextSuggestion.classList.add('cmp-search-results__search-suggestion-item--active');
                    } else {
                        searchInput.focus();
                    }
                } else {
                    const firstSuggestion = searchSuggestionsList.querySelector('.cmp-search-results__search-suggestion-item');
                    firstSuggestion.classList.add('cmp-search-results__search-suggestion-item--active');
                }
            }

            // enter - fill first suggestion or selected suggestion
            if (event.keyCode === 13) {
                const activeSuggestion = searchSuggestionsList.querySelector('.cmp-search-results__search-suggestion-item--active');
                if (activeSuggestion) {
                    event.preventDefault();
                    searchInput.value = activeSuggestion.innerText;
                    searchForm.requestSubmit();
                    resetSearchAutocomplete();
                } else if (searchAutocomplete.value.length > 0 && searchInput.value !== searchAutocomplete.value) {
                    event.preventDefault();
                    // searchInput.value = searchAutocomplete.value;
                    searchForm.requestSubmit();
                    resetSearchAutocomplete();
                } else {
                    event.preventDefault();
                    searchForm.requestSubmit();
                    searchInput.blur();
                }
            }
        });

        // Fill search input with selected suggestion
        searchSuggestionsList.addEventListener('click', (event) => {
            if (event.target.classList.contains('cmp-search-results__search-suggestion-link')) {
                searchInput.value = event.target.innerText;
                resetSearchResults();
                loadSearchData();
            }
        });

        // Close search suggestions on click outside
        searchInput.addEventListener('focusout', () => {
            setTimeout(() => {
                resetSearchAutocomplete();
            }, 200);
        });

        // Reset search
        searchReset.addEventListener('click', function (event) {
            event.preventDefault();
            resetSearchAutocomplete();
            searchFieldWrap.classList.remove('cmp-search-results__search-field-wrap--active');
            searchReset.tabIndex = -1;
            searchInput.value = '';

            const queryParams = {
                query: '',
                federations: activeFederations.join(',')
            };
            const updatedUrl = updateQueryString(window.location.href, queryParams);
            window.history.pushState({}, '', updatedUrl);

            resetSearchResults();
        });

        // Submit search form
        searchForm.addEventListener('submit', function (event) {
            event.preventDefault();
            resetSearchResults();
            loadSearchData();
        });

        // Filter tags
        searchTags.forEach(tag => {
            tag.addEventListener('click', function (event) {
                event.preventDefault();
                const isTagActive = tag.classList.contains('v-filter-tags__item--active');
                const tagId = tag.dataset.tag;

                resetSearchResults();

                if (tagId === 'all-reset') {
                    activeTags = [];
                    searchTags.forEach(tag => tag.classList.remove('v-filter-tags__item--active'));
                    loadSearchData();
                    return;
                }
                if (isTagActive) {
                    tag.classList.remove('v-filter-tags__item--active');
                    activeTags = activeTags.filter(item => item !== tagId);
                } else {
                    tag.classList.add('v-filter-tags__item--active');
                    activeTags.push(tagId);
                }
                loadSearchData();
            });
        });

        // Load more
        searchFooterLink.addEventListener('click', function (event) {
            event.preventDefault();
            currentPage++;
            loadSearchData(true);
        });

        // init search
        getFederations(federationsEndpoint)
            .then(data => {
                const federations = formatFederations(data);
                initSearch(federations, areas);
            })
            .catch(error => console.error(error));
    });
}
